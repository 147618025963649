import Image from 'next/legacy/image';

import { Link } from '@dx-ui/osc-link';

import { useMetrics } from '../../../hooks';
import { Goals } from '../../../types/Conductrics';
import { GoalByCardName, sendConductricsReward } from '../../../utils/helpers/conductrics';

import type { OffersCreditCard } from '@dx-ui/gql-types';
import { ImageLoader } from '../helpers';

type Props = {
  children: React.ReactNode;
  card: OffersCreditCard;
  className?: string;
  actionDetail?: string;
};

export const Card = ({ card, className, actionDetail, children }: Props) => {
  const { metrics } = useMetrics();

  const { cardName, link, images, feeDescription } = card;
  const applyNowLink = link[0];
  const otherLinks = link.slice(1, link.length);

  return (
    <div className={`items-center px-1 pt-12 text-center ${className}`}>
      {images[0]?.image?.image?.variants && images[0]?.altText ? (
        <div className="relative min-h-[155px] max-w-[220px] ltr:left-1/2 ltr:-translate-x-1/2 rtl:right-1/2 rtl:translate-x-1/2">
          <Image
            src={images[0]?.image?.image?.variants?.tabs[0]?.cdnLink || ''}
            alt={images[0]?.altText}
            loader={ImageLoader}
            layout="fill"
            objectFit="contain"
          />
        </div>
      ) : null}
      <h4 className="text-base font-bold">{cardName}</h4>
      <span className="mb-4 block text-sm font-bold">{feeDescription}</span>
      {applyNowLink?.linkUrl ? (
        <Link
          className="btn btn-sm btn-primary bg-brand hover:bg-brand-alt px-4 text-base"
          url={applyNowLink?.linkUrl}
          underline={false}
          cidParams={{ campaignId: applyNowLink?.campaignId as string, componentName: 'Card' }}
          isNewWindow={applyNowLink?.isNewWindow || false}
          onClick={() => {
            sendConductricsReward(Goals.CreditCardApplyNow);
            cardName && sendConductricsReward(GoalByCardName[cardName]);
            metrics.setProperty('page.attributes.actionDetail', `${actionDetail}`);
            metrics.setEventAction([{ eventAction: 'homeclick' }]);
            metrics.trackEvent('homeclick');
          }}
        >
          {applyNowLink?.linkLabel}
        </Link>
      ) : null}
      {otherLinks && otherLinks?.length > 0 ? (
        <div className="divide-border mt-4 grid grid-cols-3 ltr:divide-x rtl:divide-x-reverse">
          {otherLinks?.map((link) => (
            <Link
              adaDescription={link?.adaDescription || ''}
              className="text-brand hover:text-brand-alt whitespace-nowrap px-0.5 text-xs"
              key={`card-${cardName}-${link?.linkLabel}-link`}
              isNewWindow={link?.isNewWindow || false}
              onClick={() => sendConductricsReward(Goals.CreditCardTermsAndConditions)}
              url={link?.linkUrl}
              cidParams={{ campaignId: link?.campaignId as string, componentName: 'Card' }}
            >
              {link?.linkLabel}
            </Link>
          ))}
        </div>
      ) : null}
      <div className="flex justify-center px-3 sm:px-0 lg:px-3">{children}</div>
    </div>
  );
};
