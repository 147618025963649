import { DEFAULT_BRAND_CODE, DEPRECATED_BRAND_CODES } from './constants';

type GetThemeClassNameArgs = Partial<{
  brandCode: StringWithAutocompleteOptions<BrandCode>;
  isBrandRefresh: boolean;
}>;

const defaultArguments = {
  brandCode: DEFAULT_BRAND_CODE,
  isBrandRefresh: false,
} as const satisfies GetThemeClassNameArgs;

export function getThemeClassName({
  brandCode = defaultArguments.brandCode,
  isBrandRefresh = defaultArguments.isBrandRefresh,
}: GetThemeClassNameArgs = defaultArguments) {
  const brand = getSupportedBrandCode(brandCode).toLowerCase();
  const base = ['theme', brand].join('-');
  const refresh = isBrandRefresh ? [base, 'refresh'].join('-') : null;
  return [base, refresh].filter(Boolean).join(' ');
}

function getSupportedBrandCode(brandCode: NonNullable<GetThemeClassNameArgs['brandCode']>) {
  return DEPRECATED_BRAND_CODES.includes(brandCode) ? DEFAULT_BRAND_CODE : brandCode;
}
