import type { ImageLoaderProps } from 'next/legacy/image';

import type { OffersCreditCard, OffersCreditCardGroup } from '@dx-ui/gql-types';

export const ImageLoader = ({ src, width, quality }: ImageLoaderProps) =>
  `${src}?w=${width}&q=${quality || 75}`;

export const creditCardTypes = ['hh', 'hhsurpass', 'hhaspire', 'hhbusiness'];

export const getFilteredSegmentCrediCardContent = (
  offersCreditCard: OffersCreditCardGroup,
  segments: string[]
): OffersCreditCard => {
  if (!segments.length || offersCreditCard?.items.length === 1)
    return offersCreditCard?.items[0] as OffersCreditCard;

  return (
    offersCreditCard?.items?.find((card) =>
      card?.segmentIds?.find((segmentId) => segments?.includes(segmentId))
    ) || (offersCreditCard?.items[0] as OffersCreditCard)
  );
};
