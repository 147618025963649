import { Trans, useTranslation } from 'next-i18next';
import Image from 'next/legacy/image';
import { useMemo } from 'react';

import { Link } from '@dx-ui/osc-link';
import { Markdown } from '@dx-ui/osc-markdown';

import { useLanguage, useMetrics, useSegments } from '../../hooks';
import { useCreditCardOffersQuery } from '../../queries/generated/react-query';
import { Goals } from '../../types/Conductrics';
import { GoalByCardName, sendConductricsReward } from '../../utils/helpers/conductrics';
import { getUrlHost } from '../../utils/helpers/urlBuilder';
import { creditCardTypes, getFilteredSegmentCrediCardContent, ImageLoader } from './helpers';
import { MoreDetails } from './MoreDetails';

import type { OffersCreditCard, OffersCreditCardGroup } from '../../types/generated/types';
import { logError } from '@dx-ui/framework-logger';
import { generateError } from '@dx-ui/utilities-generate-error';

type CardListProps = {
  cardData: OffersCreditCard;
  actionDetail?: string;
};

const CardDetails = ({ cardData, actionDetail }: CardListProps) => {
  const { attributes, cardName, cardBreakdownBlocks, feeDescription, images, link, cardType } =
    cardData;
  const [
    cardBreakdownHeading,
    ,
    ,
    ,
    builtInBenefitsHeading,
    builtInBenefitsContent,
    earningstructureHeading,
    earningstructureSubHeading,
    earningstructureContent,
  ] = cardBreakdownBlocks;
  const language = useLanguage();
  const [t] = useTranslation('credit-cards');
  const { metrics } = useMetrics();
  const applyNowLink = link[0];
  const otherLinks = link?.slice(1, link.length);

  return (
    <section className="border-border flex flex-col gap-4 border-b py-6 text-center sm:ml-6 sm:flex-row sm:text-left">
      <div className="mx-auto w-2/3 flex-none sm:mx-0 sm:w-96 ltr:sm:pr-8 rtl:sm:pl-8">
        {images?.[0]?.image?.image?.variants &&
        images?.[0]?.image?.image?.variants?.tabs[0]?.cdnLink &&
        images?.[0]?.altText ? (
          <div className="relative min-h-36 max-w-64 sm:min-h-72 sm:max-w-96 ltr:left-1/2 ltr:-translate-x-1/2 rtl:right-1/2 rtl:translate-x-1/2">
            <Image
              id={`card-${cardName}`}
              src={images?.[0]?.image?.image?.variants?.tabs[0]?.cdnLink}
              alt={images?.[0]?.altText}
              loader={ImageLoader}
              layout="fill"
              objectFit="contain"
            />
          </div>
        ) : null}

        {/* “Apply Now” CTA and T&C links for desktop — hidden on mobile */}
        <div className="my-4 hidden items-center justify-between sm:flex">
          <span className="font-bold">{feeDescription}</span>
          {link?.[0]?.linkUrl ? (
            <Link
              onClick={() => {
                sendConductricsReward(Goals.CreditCardApplyNow);
                if (cardName) {
                  sendConductricsReward(GoalByCardName[cardName]);
                }
                metrics.setProperty(
                  'page.attributes.actionDetail',
                  `breakdown:apply:${actionDetail}`
                );
                metrics.setEventAction([{ eventAction: 'homeclick' }]);
                metrics.trackEvent('homeclick');
              }}
              adaDescription={link?.[0]?.adaDescription || ''}
              className="btn btn-sm btn-primary bg-brand hover:bg-brand-alt px-4 no-underline"
              isNewWindow={link?.[0]?.isNewWindow || false}
              url={link?.[0]?.linkUrl}
              cidParams={{
                campaignId: link?.[0]?.campaignId as string,
                componentName: 'CardBreakdown',
              }}
            >
              {link?.[0]?.linkLabel}
            </Link>
          ) : null}
        </div>
        {otherLinks && otherLinks?.length > 0 ? (
          <div className="hidden sm:flex ltr:divide-x rtl:divide-x-reverse">
            {otherLinks?.map(({ adaDescription, linkLabel, linkUrl, isNewWindow, campaignId }) => (
              <Link
                adaDescription={adaDescription || ''}
                className="text-brand hover:text-brand-alt whitespace-nowrap px-1 text-sm"
                key={`card-${cardName}-${linkLabel}-link`}
                isNewWindow={isNewWindow || false}
                onClick={() => sendConductricsReward(Goals.CreditCardTermsAndConditions)}
                url={linkUrl}
                cidParams={{ campaignId: campaignId as string, componentName: 'CardBreakdown' }}
              >
                {linkLabel}
              </Link>
            ))}
          </div>
        ) : null}
        {cardType !== 'business' ? (
          <p className="text-text pt-6">
            <Trans t={t} i18nKey="noCreditImpact" components={[<strong key="first" />]} />
          </p>
        ) : null}
      </div>
      <div className="max-w-prose flex-none sm:flex-1 rtl:text-center rtl:sm:text-right">
        {cardBreakdownBlocks && cardBreakdownBlocks.length ? (
          <>
            <h4 className="scroll-mt-52 text-xl font-extrabold sm:scroll-mt-12">
              {cardBreakdownHeading?.heading}
            </h4>
            <p className="text-lg">{cardBreakdownBlocks?.[1]?.description}</p>
            {attributes[0] === 'limitedTimeOffer' ? (
              <p className="border-primary text-primary mx-auto my-4 max-w-fit rounded-full border-2 px-4 py-1 font-extrabold sm:mx-0">
                {t('limitedTimeOffer')}
              </p>
            ) : null}
            <p className="my-4 font-bold sm:hidden">{feeDescription}</p>
            {cardBreakdownBlocks?.[2]?.heading ? (
              <h5 className="mb-2 mt-4 font-extrabold">
                <Markdown
                  origin={getUrlHost()}
                  language={language}
                  options={{
                    overrides: {
                      strong: {
                        props: {
                          className: 'text-primary',
                        },
                      },
                    },
                  }}
                >
                  {cardBreakdownBlocks?.[2]?.heading}
                </Markdown>
              </h5>
            ) : null}
            <p className="text-text-alt text-sm">{cardBreakdownBlocks?.[3]?.description}</p>

            <MoreDetails
              cardName={cardName}
              builtInBenefits={{ heading: builtInBenefitsHeading?.heading }}
              breakDown={{
                builtInBenefits: builtInBenefitsContent?.unorderedList,
                earningBenefits: earningstructureContent?.unorderedList,
              }}
              earningStructure={{
                heading: earningstructureHeading?.heading || '',
                subheading: earningstructureSubHeading?.description || '',
              }}
            />
          </>
        ) : null}

        {/* “Apply Now” CTA and T&C links for mobile — hidden on desktop */}
        <section className="sm:hidden">
          {applyNowLink?.linkUrl ? (
            <Link
              className="btn btn-sm btn-primary mt-4 px-4 text-base no-underline"
              url={applyNowLink?.linkUrl}
              onClick={() => {
                sendConductricsReward(Goals.CreditCardApplyNow);
                if (cardName) {
                  const cardNameOnly = cardName.substring(0, cardName.lastIndexOf(' '));
                  sendConductricsReward(GoalByCardName[cardNameOnly]);
                }
              }}
              cidParams={{
                campaignId: applyNowLink?.campaignId as string,
                componentName: 'CardBreakdown',
              }}
              {...(applyNowLink?.isNewWindow
                ? {
                    target: '_blank',
                    rel: 'noopener noreferrer',
                    'aria-label': `${applyNowLink?.adaDescription}, ${t('opensInNewWindow')}`,
                  }
                : { 'aria-label': applyNowLink?.adaDescription || '' })}
            >
              {applyNowLink?.linkLabel}
            </Link>
          ) : null}
          {otherLinks && otherLinks?.length > 0 ? (
            <div className="my-4 flex justify-center ltr:divide-x rtl:divide-x-reverse">
              {otherLinks.map(({ adaDescription, linkLabel, linkUrl, isNewWindow, campaignId }) => (
                <Link
                  adaDescription={adaDescription || ''}
                  className="text-hilton hover:text-hilton-alt whitespace-nowrap px-1 text-sm"
                  key={`card-${cardName}-${linkLabel}-link`}
                  isNewWindow={isNewWindow || false}
                  url={linkUrl}
                  cidParams={{ campaignId: campaignId as string, componentName: 'CardBreakdown' }}
                >
                  {linkLabel}
                </Link>
              ))}
            </div>
          ) : null}
          {cardType !== 'business' ? (
            <p className="text-text px-2 text-left">
              <Trans t={t} i18nKey="noCreditImpact" components={[<strong key="first" />]} />
            </p>
          ) : null}
        </section>
      </div>
    </section>
  );
};

export const CardBreakdown = () => {
  const [t] = useTranslation('credit-cards');
  const language = useLanguage();
  const segments = useSegments();

  const { data, isLoading, error } = useCreditCardOffersQuery({ language });

  const displayCardContent = useMemo(() => {
    const creditCardsContent = data?.creditCardOffers as OffersCreditCardGroup[];

    const personalCards: OffersCreditCardGroup[] =
      creditCardsContent?.filter((card) => card?.cardType === 'personal') || [];
    const businessCards: OffersCreditCardGroup[] =
      creditCardsContent?.filter((card) => card?.cardType === 'business') || [];

    if (isLoading) {
      return null;
    }

    if (error) {
      logError('CARD_BREAKDOWN', generateError(error), useCreditCardOffersQuery.name);
      return null;
    }
    return (
      <section className="flex flex-col">
        <h3 className="font-headline text-hilton text-center text-3xl font-bold sm:text-left rtl:sm:text-right">
          {t('cardBreakdown.title')}
        </h3>
        <h4 className="mt-4 text-center text-xl font-bold sm:text-left rtl:sm:text-right">
          {t('cardBreakdown.personalCardsTitle', { count: personalCards?.length })}
        </h4>
        {personalCards.map((cardData, index) => {
          const creditCardContent: OffersCreditCard = getFilteredSegmentCrediCardContent(
            cardData,
            segments
          );
          return (
            <CardDetails
              key={`card-breakdown-${creditCardContent?.cardName}`}
              cardData={creditCardContent}
              actionDetail={creditCardTypes[index]}
            />
          );
        })}
        <h5 className="mt-9 text-center text-xl font-bold sm:text-left rtl:sm:text-right">
          {t('cardBreakdown.businessCardsTitle', { count: businessCards?.length })}
        </h5>
        {businessCards.map((cardData) => {
          const creditCardContent: OffersCreditCard = getFilteredSegmentCrediCardContent(
            cardData,
            segments
          );
          return (
            <CardDetails
              key={`card-breakdown-${creditCardContent?.cardName}`}
              cardData={creditCardContent}
              actionDetail={creditCardTypes[3]}
            />
          );
        })}
      </section>
    );
  }, [data?.creditCardOffers, isLoading, error, t, segments]);

  return displayCardContent;
};
